import Gateway from "../../Library/Api/Gateway";
import { isEmpty } from "../../Library/Helpers";
import URLS from "../../Library/Constants/URLs";

const { TAM_URL, SERVICE_CALL_URL } = URLS;

export const Constants = {
  ACCESS_TOKEN: "EwAccessToken",
  REFRESH_TOKEN: "EwRefreshToken",
  TAM_ACCESS_TOKEN: "tamAccessToken",
  SUBDOMAIN_COOKIE: "subDomain",
  LOGIN_VIEW_STATE: {
    CUSTOMER_CODE_VIEW: "C",
    LOGIN_FORM_VIEW: "L",
    CHANGE_PASSWORD_VIEW: "CP",
    SET_PASSWORD_VIEW: "SP",
    OTP_VIEW: "O",
    FORGET_PASSWORD_VIEW: "FP",
    TAM_LOGIN: "T",
  },
  URL_PARAMS: {
    SET_PASSWORD: "userId",
    TAM: "tam",
    SSO_TOKEN: "code",
    SSO_SUB_DOMAIN: "state",
  },
  LABEL_MAPPER: {
    E: "Email",
    M: "Mobile",
    C: "Employee Code",
    default: "User Name",
  },
  LOGIN_TYPES: {
    LOGIN: "L",
    SSO: "S",
  },
  PAYLOAD_MODE: {
    LOGIN: "LU",
    SEND_EMAIL: "SE",
    SSO: "GT",
    SET_PASSWORD: "SP",
    CHANGE_PASSWORD: "CP",
    // SSO_LOGIN: "GT",
  },
  ROLES: ["O", "T", "M"],
  TAM_END_POINT: "face-recognition/login/health",
  TYPE_EFFECT_STRING_LIST: [
    "Leave & Attendance",
    "Travel & Expense",
    "Performance Management",
    "Payroll & Taxation",
    "Hiring & Onboarding",
    "Employee Separation",
    "Learning & Development",
    "Timesheets",
    "Mobile App",
  ],
};

export const getAuthDocument = (
  props = {
    subDomain: null,
  }
) => {
  const { subDomain } = props;
  if (isEmpty(subDomain)) return Promise.reject("Sub-domain missing");
  let payload = {
    SubDomain: subDomain,
    Mode: "GD",
    PasswordPolicy: "Y",
  };
  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Login" },
  });
};

export const performLogin = (
  props = {
    name: null,
    password: null,
    subDomain: null,
    authCode: null, // for sso
    mode: null,
  }
) => {
  let { name, password, subDomain, authCode, mode } = props;
  const payloadMode = Constants.PAYLOAD_MODE;
  if (!mode) mode = payloadMode.LOGIN;
  let payload = {
    UserLoginName: name,
    Password: password,
    SubDomain: subDomain,
    Mode: mode,
    AuthorisationCode: "",
    DeviceType: "BA",
  };
  if (authCode) {
    payload.AuthorisationCode = authCode;
    payload.Mode = payloadMode.SSO;
  }
  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Login" },
  });
};

export const onKeyPress = (event, callBackFunction) => {
  if (event.key === "Enter") {
    event.preventDefault();
    if (callBackFunction) callBackFunction();
  }
};

export const changePassword = (
  props = {
    userName: "",
    password: "",
    oldPassword: "",
    subDomain: "",
  }
) => {
  const { userName, password, subDomain, oldPassword } = props;
  let payload;

  payload = {
    UserLoginName: userName,
    NewPassword: password,
    OldPassword: oldPassword,
    SubDomain: subDomain,
    Mode: Constants.PAYLOAD_MODE.CHANGE_PASSWORD,
  };

  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: {
      RequestType: "Login",
    },
  });
};

export const setPassword = (
  props = {
    subDomain: "",
    userId: "",
    password: "",
  }
) => {
  const { subDomain, userId, password } = props;
  if (isEmpty(subDomain)) return Promise.reject("Subdomain Missing");
  if (isEmpty(userId)) return Promise.reject("User Id missing");
  const payload = {
    UserLoginName: userId,
    NewPassword: password,
    SubDomain: subDomain,
    Mode: Constants.PAYLOAD_MODE.SET_PASSWORD,
  };
  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: {
      RequestType: "Login",
    },
  });
};

export const getRole = (roles) => {
  let arr = [];
  Constants.ROLES.forEach((ele) => {
    if (roles.includes(ele)) arr.push(ele);
  });

  return arr;
};

export const tamLogin = (
  props = {
    username: "",
    password: "",
  }
) => {
  const { username, password } = props;
  let payload = {
    username: username,
    password: password,
  };
  return Gateway({
    url: TAM_URL + Constants.TAM_END_POINT,
    payload,
  });
};

export const validatePassword = (options, password) => {
  const errors = [];

  // Minimum password length
  const minLength = parseInt(options.MinimumPasswordLength, 10);
  if (isNaN(minLength) || minLength <= 0) {
    errors.push("Invalid minimum password length.");
  } else if (password.length < minLength) {
    errors.push(`Password should be at least ${minLength} characters long.`);
  }

  // Maximum password length
  const maxLength = parseInt(options.MaximumPasswordLength, 10);
  if (isNaN(maxLength) || maxLength <= 0) {
    errors.push("Invalid maximum password length.");
  } else if (maxLength < password.length) {
    errors.push(`Password should not exceed ${maxLength} characters.`);
  }

  // Lowercase
  if (options.Lowercase === "1" && !/[a-z]/.test(password)) {
    errors.push("Password should contain at least one lowercase letter.");
  }

  // Uppercase
  if (options.Uppercase === "1" && !/[A-Z]/.test(password)) {
    errors.push("Password should contain at least one uppercase letter.");
  }

  // Digits
  if (options.Digits === "1" && !/\d/.test(password)) {
    errors.push("Password should contain at least one digit.");
  }

  // Special characters
  if (options.SpecialCharacters === "1" && !/\W/.test(password)) {
    errors.push("Password should contain at least one special character.");
  }

  if (errors.length === 0) {
    return { success: true };
  } else {
    return { error: errors };
  }
};
