import URLs from "../../Constants/URLs";
import Gateway from "../Gateway";
import { getTenantID, getUserId } from "../../Helpers";
import {
  AttendancePropTypes,
  ManageApplicability,
  ManageFormsManager,
  ManageWorkflow,
  NotificationProps,
  WorkdaysPropTypes,
} from "../types";

const { SERVICE_CALL_URL } = URLs;

export const manageApplicability = (props: ManageApplicability) => {
  const {
    mode,
    applicabilityId,
    subjectUsers,
    transactionUserId,
    featureId,
    featureVariantId,
    payload: applicabilityPayload,
  } = props;

  return new Promise((resolve, reject) => {
    if (!mode) {
      return reject("Mode is required");
    }

    let payload = null;

    if (mode === "C") {
      payload = {
        Mode: mode,
        TenantID: getTenantID(),
        FeatureID: featureId,
        FeatureVariantID: featureVariantId || undefined,
        ...applicabilityPayload,
      };
    }
    if (mode === "U") {
      payload = {
        Mode: mode,
        ApplicabilityID: applicabilityId,
        TenantID: getTenantID(),
        FeatureID: featureId,
        ...applicabilityPayload,
      };
    }
    if (mode === "V") {
      payload = {
        Mode: mode,
        ApplicabilityID: applicabilityId,
        TenantID: getTenantID(),
      };
    }
    if (mode === "F") {
      payload = {
        Mode: mode,
        TenantID: getTenantID(),
        FeatureID: featureId,
        TransactionUserID: transactionUserId,
        SubjectUsers: [...(subjectUsers || [])],
      };
    }
    if (mode === "UA") {
      payload = {
        Mode: mode,
        TenantID: getTenantID(),
        ApplicabilityID: applicabilityId,
      };
    }

    return Gateway({
      url: SERVICE_CALL_URL,
      payload: {
        ...payload,
      },
      headers: { RequestType: "Applicability" },
    })
      .then((res) => {
        return resolve(res);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

export const manageWorkflow = (props: ManageWorkflow) => {
  const { featureId, mode, workflowData, workflowId } = props;
  let body = null;
  if (mode === "CW") {
    body = {
      TenantID: getTenantID(),
      Mode: "CW",
      FeatureID: featureId || null,
      Payload: { ...workflowData },
      EffectiveFrom: new Date().getTime(),
      EffectiveTill: new Date(new Date().getFullYear() + 1, 0, 1).getTime(),
    };
  }

  if (mode === "GW") {
    body = {
      TenantID: getTenantID(),
      Mode: "GW",
      WorkflowID: workflowId,
    };
  }

  if (mode === "EW") {
    body = {
      TenantID: getTenantID(),
      Mode: "EW",
      FeatureID: featureId || null,
      WorkflowID: workflowId,
      Payload: { ...workflowData },
      EffectiveFrom: new Date().getTime(),
      EffectiveTill: new Date(new Date().getFullYear() + 1, 0, 1).getTime(),
    };
  }

  // const payload = {
  //   function: "workflow",
  //   body,
  // };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...body,
        },
        headers: { RequestType: "Workflow" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const manageFormsManager = (props: ManageFormsManager) => {
  const {
    mode = "CFT",
    formResponse = null,
    formTemplateId = "",
    payload = null,
  } = props;
  return new Promise(async (resolve, reject) => {
    const finalPayload = {
      function: "form",
      Mode: mode,
      FormTemplateID: formTemplateId,
      TenantID: getTenantID(),
      TemplateData: payload,
      FormData: formResponse,
    } as any;

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...finalPayload,
        },
        headers: { RequestType: "Function" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const manageWorkdays = (props: WorkdaysPropTypes) => {
  const {
    mode = "WH",
    tenantId = getTenantID(),
    userId = parseInt(getUserId()),
    fromDate = 0,
    tillDate = Date.now(),
  } = props;

  return new Promise(async (resolve, reject) => {
    const payload = {
      function: "workDays",
      Mode: mode,
      TenantID: tenantId,
      UserID: userId,
      FromDate: fromDate,
      TillDate: tillDate,
    } as any;

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...payload,
        },
        headers: { RequestType: "Workday" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const manageAttendance = (props: AttendancePropTypes) => {
  const {
    From = Date.now(),
    Mode,
    Tenant = getTenantID(),
    User = parseInt(getUserId()),
    AttendanceType,
    Method = "WB",
    Latitude = 17.560223,
    Longitude = 78.472896,
  } = props;

  return new Promise(async (resolve, reject) => {
    let params;

    if (Mode === "INFO") {
      params = {
        Mode: Mode,
        Tenant: Tenant,
        User: User,
        From: From,
      };
    }

    if (Mode === "MARK") {
      params = {
        Mode: Mode,
        Tenant: Tenant,
        User: User,
        AttendanceType,
        Method,
        Latitude,
        Longitude,
        Device: "12345",
      };
    }

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...params,
        },
        headers: { RequestType: "Attendance" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const manageNotification = (props: NotificationProps) => {
  const { Mode, NotificationObject, NotificationTemplateID } = props;
  return new Promise(async (resolve, reject) => {
    let params: any = { Mode };
    if (Mode === "CNT") {
      params = { ...params, NotificationObject };
    }
    if (Mode === "UNT") {
      params = { ...params, NotificationObject, NotificationTemplateID };
    }
    if (Mode === "GNT") {
      params = { ...params, NotificationTemplateID };
    }
    const payload = {
      function: "notificationManagerTemplate",
      TenantID: getTenantID(),
      ...params,
    } as any;

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...payload,
        },
        headers: { RequestType: "Function" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};
