import React from "react";
import { RadioPropTypes } from "./types";
import { Radio, styled } from "@mui/material";

const CustomRadioIcon = styled("span")(({ theme }: { theme: any }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  backgroundColor: theme.palette.primary["100"],
}));

const CustomRadioSelectedIcon = styled(CustomRadioIcon)(
  ({ theme }: { theme: any }) => ({
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: `radial-gradient(${theme.palette.secondary.main},${theme.palette.secondary.main} 28%,transparent 32%)`,
      content: '""',
    },
  })
);

const Radio_1: React.FC<RadioPropTypes> = (props: RadioPropTypes) => {
  const { value, name, onChange, ...restProps } = props;
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomRadioSelectedIcon />}
      icon={<CustomRadioIcon />}
      name={name}
      value={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value === "true", name);
        }
      }}
      {...restProps}
    />
  );
};

export default Radio_1;
