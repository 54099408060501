import URLs from "../../Constants/URLs";
import Gateway from "../Gateway";
import { getTenantID, getUserId } from "../../Helpers";
import {
  CreateUserPropTypes,
  SubmitToDocumentManager,
  NeedToActActionProps,
} from "../types";

const { SERVICE_CALL_URL } = URLs;

export const uploadFile = (
  props = {
    file: {},
    type: "",
    folder: "",
  }
) => {
  const { file, type, folder } = props;
  const formData = new FormData();
  formData.append("File", file as string | Blob);
  formData.append("TenantID", getTenantID());
  formData.append("Folder", folder);
  formData.append("FileType", type);

  return Gateway({
    url: SERVICE_CALL_URL,
    payload: formData,
    headers: {
      RequestType: "File",
      "Content-Type": `multipart/form-data`,
    },
  });
};

export const createUser = (props: CreateUserPropTypes) => {
  const { Mode = "C", UserRoles = "E", EntityID } = props;

  const payload = {
    function: "createUser",
    Mode,
    UserRoles,
    TenantID: getTenantID(),
    EntityID: EntityID || null,
  };
  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Function" },
  });
};

export const submitToDocumentManager = (props: SubmitToDocumentManager) => {
  const {
    requestType = "C",
    documentType = "TRN",
    featureId,
    featureVariantId,
    featureVariantName = "",
    menuOptionId,
    transactionId,
    effectiveFrom = null,
    payload,
    stepId = null,
    actionId = null,
    subjectUser = parseInt(getUserId()),
    transactionType,
  } = props;

  if (!featureId) return Promise.reject("Feature not given");

  let finalPayload = {
    RequestType: requestType,
    TransactionType: transactionType,
    DocumentType: documentType,
    TenantID: getTenantID(),
    FeatureID: featureId,
    FeatureVariantID: featureVariantId,
    FeatureVariantName: featureVariantName,
    StepID: stepId,
    ActionID: actionId,
    SubjectUser: subjectUser,
    MenuOptionID: menuOptionId || undefined,
    TransactionUser: parseInt(getUserId()),
    Payload: payload,
    EffectiveFrom: effectiveFrom,
    EffectiveTill: null,
  } as any;

  if (transactionId) finalPayload.TransactionID = transactionId;

  return Gateway({
    payload: finalPayload,
    url: SERVICE_CALL_URL,
    headers: { RequestType: "Document" },
  });
};

/**
 * This function is used to snooze or archive the need to act notification
 * @param props
 * @returns
 */
export const needToActNotificationAction = (props: NeedToActActionProps) => {
  const {
    NotificationID = "",
    UpdateType = "",
    SnoozeTill = undefined,
  } = props;

  const payload: {
    function: string;
    Mode: string;
    UserID: string;
    TenantID: any;
    NotificationID: string;
    UpdateType: string;
    SnoozeTill?: number;
  } = {
    function: "needToAct",
    Mode: "UNR",
    UserID: getUserId(),
    TenantID: getTenantID(),
    NotificationID: NotificationID,
    UpdateType: UpdateType,
  };

  if (SnoozeTill !== undefined) {
    payload.SnoozeTill = SnoozeTill;
  }

  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Function" },
  });
};
