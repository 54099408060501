import store from "../../../Redux/Store";
import URLs from "../../Constants/URLs";
import Gateway from "../Gateway";
import { getTenantID, getUserId } from "../../Helpers";
import {
  GetCountryPropTypes,
  GetCurrencyPropTypes,
  GetDataPropTypes,
  GetDimensionTree,
  GetFeatureDocPropTypes,
  GetFilePropTypes,
  GetLeTypePropTypes,
  GetMenu,
  GetTimezones,
  GetUserCardProps,
  NinTypePropTypes,
  TinTypePropTypes,
  NTAProps,
  CheckNTAProps,
} from "../types";

const { SERVICE_CALL_URL } = URLs;

/**
 * This function is used to get the list of all need to act data
 * @param {NTAProps}
 * @author Ankit Dhawan
 * @return {Promise}
 */
export const getNeedToActList = (props: NTAProps = {}) => {
  const { Status } = props;
  const data = {
    function: "needToAct",
    UserID: getUserId(),
    TenantID: getTenantID(),
    Mode: "GNR",
    Status,
  };
  return Gateway({
    url: SERVICE_CALL_URL,
    payload: data,
    headers: { RequestType: "Function" },
  });
};

/**
 * This function is used to check if new need to act data is available
 * @param {CheckNTAProps}
 * @author Ankit Dhawan
 */
export const checkForNewNeedToAct = (props: CheckNTAProps) => {
  const { lastNtaDate } = props;
  const data = {
    function: "needToAct",
    UserID: getUserId(),
    TenantID: getTenantID(),
    Mode: "CNR",
    ToDate: lastNtaDate,
  };
  return Gateway({
    url: SERVICE_CALL_URL,
    payload: data,
    headers: { RequestType: "Function" },
  });
};

export const getFile = (props: GetFilePropTypes) => {
  const { FileID, Mode = "D" } = props;
  if (!FileID) return Promise.reject("File id not provided");
  const payload = {
    function: "file",
    Mode,
    FileID,
    TenantID: getTenantID(),
  };
  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Function" },
  });
};

export const getLeType = (props: GetLeTypePropTypes) => {
  const { mode = "IDC", countryId } = props;
  const payload = {
    function: "leType",
    mode,
    countryCode: countryId,
  };
  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Function" },
  });
};

export const getCountry = (props: GetCountryPropTypes) => {
  let { mode = "CLC", code = null } = props;

  let payload = { function: "country", mode, code };

  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Function" },
  });
};

export const getTimezones = (props: GetTimezones) => {
  const { mode = "LC", countryId = null } = props;
  return Gateway({
    url: SERVICE_CALL_URL,
    payload: {
      function: "timezone",
      mode,
      countryId,
    },
    headers: { RequestType: "Function" },
  });
};

export const getData = (props: GetDataPropTypes) => {
  const {
    featureId,
    featureVariantId,
    status = "A",
    transactionId,
    entityId,
    subjectUserId,
    dataMatch = {},
    startIndex = 0,
    limit = 10,
    documentType = "TRF",
  } = props;

  let { returnData = ["Header", "Fields"] } = props;

  if (!featureId) return Promise.reject("Feature type not given");

  const allReturnData = [
    "Header",
    "Fields",
    "Applicability",
    "WorkflowID",
    "FieldAttributes",
    "Entities",
    "TransactionInitiation",
    "TransactionCancellation",
    "TransactionCancellations",
  ];

  if (returnData.length > 0 && returnData[0] === "*") {
    returnData = [...allReturnData];
  }

  let pagination = "[0^*]";
  if (startIndex !== undefined) {
    if (typeof startIndex !== "number" || startIndex < 0)
      return Promise.reject("Invalid page number");
    pagination = `[${startIndex}^${limit || 10}]`;
  }

  const tenantCode = getTenantID();

  const documentMatch = `[${tenantCode}^${documentType}^${featureId}^${
    featureVariantId || "*"
  }^${status || "A"}^${transactionId || "*"}^${entityId || "*"}^${
    subjectUserId || "*"
  } ]`;

  let dataFields: string[][] = [];

  const operatorMapping = {
    "<": "<",
    ">": ">",
    "=": "=",
    ":": "IN",
    "!:": "NOT IN",
    "!=": "<>",
  };

  Object.entries(dataMatch || {}).forEach(([key, field]) => {
    if (!field) throw Error("Data match error [corrupt data]");
    let { operator, value } = field;
    const finalOperator = operatorMapping[operator];

    if (value === "") {
      value = "empty";
    }

    if (Array.isArray(value)) {
      if (!["IN", "NOT IN"].includes(finalOperator))
        throw Error("Corrupt Data for array");
      dataFields.push([`${key}^${finalOperator}^${value.join("^")}`]);
    } else {
      dataFields.push([`${key}^${finalOperator}^${value}`]);
    }
  });

  const finalDataFields = JSON.stringify(dataFields)
    .replaceAll('"', "")
    .replaceAll(",", "|");

  let expression = `${documentMatch},${finalDataFields},${`[${returnData.join(
    "^"
  )}]`},${pagination}`;
  console.log("FROM getdate: ", expression);
  let payload = {
    function: "getData",
    expression,
  };

  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Function" },
  });
};

export const getDimensionTree = (props: GetDimensionTree) => {
  const { Dimension, DimensionID, Direction = "D", Mode = "A" } = props;
  if (!Dimension) {
    return Promise.reject("Dimension featureId is required! Request failed");
  }
  if (!DimensionID) {
    return Promise.reject(
      "DimensionID is required to get the tree! Request Failed"
    );
  }

  const TenantID = store.getState().AuthReducer?.tenantCode;
  const finalPayload = {
    function: "dimensionTree",
    TenantID,
    Dimension,
    DimensionID,
    Direction,
    Mode,
  };

  return Gateway({
    url: SERVICE_CALL_URL,
    payload: finalPayload,
    headers: { RequestType: "Function" },
  });
};

export const getMenu = (props: GetMenu) => {
  const { MenuType, Mode, OptionID } = props;
  return new Promise(async (resolve, reject) => {
    const finalPayload = {
      Mode: Mode || "FM",
      TenantID: getTenantID(),
      UserID: getUserId(),
      MenuType: MenuType || "UM",
      OptionID: OptionID || "",
    };

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...finalPayload,
        },
        headers: { RequestType: "Menu" },
      });
      return resolve(res.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getNinType = (props: NinTypePropTypes) => {
  const { mode = "CD", countryId, ninType, nin } = props;
  return new Promise(async (resolve, reject) => {
    const finalPayload = {
      function: "ninType",
      mode: mode,
      countryid: countryId,
      nintype: ninType,
      nin: nin,
    };

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...finalPayload,
          // function: "ninType",
          // expression: `ninType('${mode}','${countryId}')`,
        },
        headers: { RequestType: "Function" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getTinType = (props: TinTypePropTypes) => {
  const { mode = "CD", countryId, tinType, tin } = props;
  return new Promise(async (resolve, reject) => {
    const finalPayload = {
      function: "tinType",
      mode: mode,
      countryid: countryId,
      nintype: tinType,
      nin: tin,
    };

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...finalPayload,
        },
        headers: { RequestType: "Function" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getFeatureDoc = (props: GetFeatureDocPropTypes) => {
  const { Mode, FeatureID, DocumentType, ModuleID } = props;
  let payload = {
    Mode: Mode || "",
    FeatureID: FeatureID || "",
    DocumentType: DocumentType || "TFT",
    ModuleID: ModuleID || "",
    function: "getFeatureDoc",
  };

  return Gateway({
    url: SERVICE_CALL_URL,
    payload,
    headers: { RequestType: "Function" },
  });
};

export const getCurrency = (props: GetCurrencyPropTypes) => {
  const { Mode = "ACC", CountryID = "", CurrencyCode = "" } = props;
  return new Promise(async (resolve, reject) => {
    const finalPayload = {
      function: "currency",
      mode: Mode,
      code: CurrencyCode,
      id: CountryID,
    };

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...finalPayload,
        },
        headers: { RequestType: "Function" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getUserCardDetails = (props: GetUserCardProps) => {
  const { Text = "", Mode = "U", UserID = null } = props;
  return new Promise(async (resolve, reject) => {
    const finalPayload = {
      function: "getUserCardDetails",
      Mode,
      TenantID: getTenantID(),
      UserID,
      Text,
    };

    try {
      const res = await Gateway({
        url: SERVICE_CALL_URL,
        payload: {
          ...finalPayload,
        },
        headers: { RequestType: "Function" },
      });
      return resolve(res);
    } catch (error) {
      return reject(error);
    }
  });
};
